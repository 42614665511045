export default {
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "German": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "Italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "Español": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "Swedish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swedish"])},
  "config": {
    "store_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store_en"])},
    "store_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inquiry", "@", "cemo.de"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.linkedin.com/company/cemo-safe-storage/"])},
    "youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/", "@", "cemo-group"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
  "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to homepage"])},
  "Call us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call us"])},
  "Pricefinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Finder"])},
  "SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "home": {
    "home-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current product prices and delivery times – at a click."])},
    "type-sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter SKU"])},
    "start-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Search"])},
    "invalid_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least 4 digits."])}
  },
  "search-result": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your result for:"])},
    "SKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])}
  },
  "product": {
    "price_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price valid until:"])},
    "shipping_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus shipping costs DE:"])},
    "shipping_costs_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus shipping costs AT:"])},
    "shipping_additional_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specified shipping costs apply to delivery within Germany except islands. For multiple products, see the shipping cost table"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date: approx."])},
    "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
    "product_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product details"])},
    "shipping_table_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.cemo.de/fracht/"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog page: "])},
    "sku_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU incomplete, please try again."])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products found."])},
    "on_inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On inquiry"])},
    "price_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PG"])},
    "price_add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excl. VAT"])},
    "delivery_time_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Delivery times may vary due to order conditions."])}
  },
  "contact": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEMO GmbH"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Backenländern 5"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["71384"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weinstadt"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "imprint_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://shop.cemo.de/impressum"])},
    "privacy_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://shop.cemo.de/datenschutz"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
  },
  "add_to_home_screen": {
    "banner_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add app to <br> HOME screen <br>"])},
    "page_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "sub_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add CEMO app to HOME screen"])},
    "ios": {
      "add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use Safari browser"])}
    },
    "android": {
      "add_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "cookies_disabled_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have disabled cookies in your browser. This app will not function properly, please enable cookies."])},
  "Base Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base Data"])},
  "account": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "logged-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
    "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, you will be logged out."])},
    "priceFinderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Price Finder"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "noAddressesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not provided a billing and/or shipping address. Please log in at https://shop.cemo.de and update the data. Then you can order here in the app."])}
  },
  "cart": {
    "addToCartButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Cart"])},
    "addToCartSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product added to cart"])},
    "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty"])},
    "checkout_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])}
  },
  "checkout": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "chooseAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send here"])},
    "continue_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Payment"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
    "shipping_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Methods"])},
    "shippingRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method is required"])},
    "paymentRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method is required"])},
    "agreementRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept!"])},
    "checkoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successful!"])},
    "orderSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Thank you for your order. The order number is: ", _interpolate(_named("orderNumber")), ". You will receive an order confirmation by email shortly."])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])}
  },
  "address": {
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "sameAsBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as billing"])}
  }
}