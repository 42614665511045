import axios from 'axios';
import store from "@/store";
import apiConfig from "@/config/apiConfig";
import authHeader from "@/services/auth-header";
import authHeaderAdmin from "@/services/auth-header-admin";
const API_URL = apiConfig.getApiUrl();

import { App} from "../App.vue";
import {createApp} from "vue";
const app = createApp(App);
const emitter = app.config.globalProperties.emitter;
console.log("EMITTER");
console.log(emitter);
class CartService {
    getCart(store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .get(API_URL + store_code + '/V1/carts/mine', {headers: authHeader()})
                .then(response => {
                        console.log(response);
                        if (response.data) {
                            console.log("cart data");
                            console.log(response.data);
                            localStorage.setItem('cart', JSON.stringify(response.data));
                        }
                        return response.data;
                    }
                );
        } else {
            let $cartId = store.state.cart.guestCartId;
            if($cartId){
                return axios
                    .get(API_URL + store_code + '/V1/guest-carts/'+$cartId, {})
                    .then(response => {
                            console.log(response);
                            if (response.data) {
                                console.log("cart data");
                                console.log(response.data);
                                localStorage.setItem('cart', JSON.stringify(response.data));
                            }
                            return response.data;
                        }
                    );
            } else {
                return this.createCart().then(cartId=>{
                    store.dispatch("cart/setGuestCartId",cartId)
                    return axios
                        .get(API_URL + store_code + '/V1/guest-carts/'+cartId, {})
                        .then(response => {
                                console.log(response);
                                if (response.data) {
                                    console.log("cart data");
                                    console.log(response.data);
                                    localStorage.setItem('cart', JSON.stringify(response.data));
                                    store.dispatch("cart/setCartData",response.data)
                                }
                                return response.data;
                            }
                        );
                })
            }

        }

    }
    getCartItems(store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .get(API_URL + store_code + '/V1/carts/mine/items', {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .get(API_URL + store_code + '/V1/guest-carts/'+$cartId+'/items', {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    getCartTotals(store_code = "store_de") {

        if(store.state.auth.status.loggedIn) {
            return axios
                .get(API_URL + store_code + '/V1/carts/mine/totals', {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            if($cartId) {
                return axios
                    .get(API_URL + '/V1/guest-carts/'+$cartId+'/totals', {})
                    .then(response => {
                        console.log(response);
                        return response.data;
                    });
            } else {
                return this.createCart().then(cartId=>{
                    store.dispatch("cart/setGuestCartId",cartId)
                    axios
                        .get(API_URL + '/V1/guest-carts/'+cartId, {})
                        .then(response => {
                                console.log(response);
                                if (response.data) {
                                    console.log("cart data");
                                    console.log(response.data);
                                    localStorage.setItem('cart', JSON.stringify(response.data));
                                    store.dispatch("cart/setCartData",response.data)
                                }
                            }
                        );
                    return axios
                        .get(API_URL + '/V1/guest-carts/'+cartId+'/totals', {})
                        .then(response => {
                            console.log(response);
                            return response.data;
                        });
                })
            }
        }
    }
    getCartTotalsInformation(addressInformation, store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + store_code + '/V1/carts/mine/totals-information',{addressInformation}, {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + store_code + '/V1/guest-carts/'+$cartId+'/totals-information',{addressInformation}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    createCart(store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + store_code + '/V1/carts/mine',{}, {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            return axios
                .post(API_URL + store_code + '/V1/guest-carts',{}, {})
                .then(response => {
                    console.log("GUEST CART ID RESP");
                    console.log(response);
                    localStorage.setItem('guestCartId', JSON.stringify(response.data));
                    return response.data;
                });
        }

    }
    createGuestCart(store_code = "store_de") {
        return axios
            .post(API_URL + store_code + '/V1/guest-carts',{})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    addItemToCart(cartItem, store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + store_code + '/V1/carts/mine/items',{cartItem}, {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + store_code + '/V1/guest-carts/'+$cartId+'/items',{cartItem}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                })
                .catch(error=>{
                    if (error.response && error.response.status === 404) {
                        // Handle 404 error
                        console.log('Error 404: Resource not found');
                        this.createCart().then(cartId=>{
                            console.log("cartId");
                            console.log(cartId);
                            store.dispatch("cart/createCart");
                        });
                    } else {
                        // Handle other errors
                        console.log('An error occurred:', error.message);
                    }
                })
                ;

        }
    }
    updateCartItemQty(cartItem, store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + store_code + '/V1/carts/mine/items',{cartItem}, {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + store_code + '/V1/guest-carts/'+$cartId+'/items',{cartItem}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    deleteCartItem(itemId, store_code = "store_de") {
        if(store.state.auth.status.loggedIn) {
            return axios
                .delete(API_URL + store_code + '/V1/carts/mine/items/'+itemId, {headers: authHeader()})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .delete(API_URL + store_code + '/V1/guest-carts/'+$cartId+'/items/'+itemId)
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }
    }
    estimateShippingMethods(address){
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + '/V1/carts/mine/estimate-shipping-methods',{address}, {headers: authHeader()})
                .then(response => {
                    console.log("estimate shipping methods");
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + '/V1/guest-carts/'+$cartId+'/estimate-shipping-methods',{address}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }
    }
    setShippingInformation(addressInformation){
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + '/V1/carts/mine/shipping-information',{addressInformation}, {headers: authHeader()})
                .then(response => {
                    console.log("shipping-information");
                    console.log(response.data);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + '/V1/guest-carts/'+$cartId+'/shipping-information',{addressInformation}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    setPaymentInformation(billingAddress,paymentMethod,email=null){
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + '/V1/carts/mine/payment-information',{billingAddress: billingAddress, paymentMethod: paymentMethod}, {headers: authHeader()})
                .then(response => {
                    console.log("payment-information");
                    console.log(response.data);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + '/V1/guest-carts/'+$cartId+'/payment-information',{email: email, billingAddress: billingAddress, paymentMethod: paymentMethod}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    isShippingSameAsBilling() {
        return this.getShippingAddressFromCart().same_as_billing;
    }

    getBillingAddressFromCart(){
        return axios
            .get(API_URL + '/V1/carts/mine/billing-address', {headers: authHeader()})
            .then(response => {
                console.log("cart billing address");
                console.log(response);
                return response.data;
            });

    }
    setBillingAddressFromCart(address){
        if(store.state.auth.status.loggedIn) {
            return axios
                .post(API_URL + '/V1/carts/mine/billing-address',{address}, {headers: authHeader()})
                .then(response => {
                    console.log("cart billing address");
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .post(API_URL + '/V1/guest-carts/'+$cartId+'/billing-address',{address}, {headers: authHeader()})
                .then(response => {
                    console.log("cart billing address");
                    console.log(response);
                    return response.data;
                });
        }


    }
    getPaymentMethods(){
        if(store.state.auth.status.loggedIn) {
            return axios
                .get(API_URL + '/V1/carts/mine/payment-methods', {headers: authHeader()})
                .then(response => {
                    console.log("Payment Methods");
                    console.log(response);
                    return response.data.filter(paymentMethod => paymentMethod.code !== 'paypal_express');
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .get(API_URL + '/V1/guest-carts/'+$cartId+'/payment-methods', {})
                .then(response => {
                    console.log(response);
                    return response.data.filter(paymentMethod => paymentMethod.code !== 'paypal_express');
                });
        }

    }
    setPaymentMethod(method) {

        if(store.state.auth.status.loggedIn) {
            return axios
                .put(API_URL + '/V1/carts/mine/selected-payment-method', {method},{headers: authHeader()})
                .then(response => {
                    console.log("Set Selected Payment Method");
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .put(API_URL + '/V1/guest-carts/'+$cartId+'/selected-payment-method',{method}, {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }

    }
    getSelectedPaymentMethod() {
        if(store.state.auth.status.loggedIn) {
            return axios
                .get(API_URL + '/V1/carts/mine/selected-payment-method', {headers: authHeader()})
                .then(response => {
                    console.log("Get Selected Payment Methods");
                    console.log(response);
                    return response.data;
                });
        } else {
            let $cartId = store.state.cart.guestCartId;
            return axios
                .get(API_URL + '/V1/guest-carts/'+$cartId+'/selected-payment-method', {})
                .then(response => {
                    console.log(response);
                    return response.data;
                });
        }
    }
    getShippingAddressFromCart() {
        let cart = JSON.parse(localStorage.getItem('cart'));
        return cart.extension_attributes['shipping_assignments'][0].shipping.address;
    }
    getAgreements() {
        return axios
            .get(API_URL + '/V1/carts/licence', {headers: authHeaderAdmin()})
            .then(response => {
                console.log("Agreements");
                console.log(response);
                return response.data;
            },
                error => {
                    console.log(error);
                }
            );


    }
}
export default new CartService();