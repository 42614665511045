
export default {

  getApiUrl() {
    return "https://shop.cemo.de/rest/";
  },
  getApiUser() {
    return  {
      "username": process.env.VUE_API_USER,
      "password": process.env.VUE_API_PW
    }
  },
  getMediaUrl() {
    return "https://shop.cemo.de/media/catalog/product";
  },
  getPlaceholderUrl(language="de") {
    return require(`../assets/img/explanation/${language}/placeholder.png`)
  }
};
//stefan.peretzki@binaerdenker.de
//zfu8J6pMAd4dHAi
